@use "src/styles/variables/colors" as colors;

.Input, .Input:focus {  
  background: colors.$white;
  border: 1px solid colors.$dark-gray;
  border-radius: 0;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  padding-left: 16px;
}

.Select {
  background: transparent;
  border: 1px solid colors.$dark-gray;
  border-radius: 0;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  padding-right: 48px;
  appearance: none;
  z-index: 2000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: auto;
  width: 100%;
  height: 100%;
}

.SelectBlock {
  position: relative;
  z-index: 0;
  height: 48px;
  width: 100%;

  svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    z-index: 1000;
  }
}

.MultiSelect {
  min-height: 48px;
  width: 100%;
}

.RegularCheckbox {
  margin-right: 12px;
}