@use '../../../styles/variables/colors' as colors;

.DoctorStats {
  // padding: 25px;

  .DoctorRowContainer {
    padding: 20px 32px 20px 32px;
    margin-bottom: 2.5rem;
    // background-color: red;

    > article:nth-child(1) {
      border-bottom: solid #3AB5AD 1px;
      margin-bottom: 1.3rem;
    }
  }

  .DoctorDropdownContainer {
    min-height: 200px;
  }

  .DoctorSecondRowContainer {
    display: flex;
    flex-direction: row;
    margin-top: 0.8rem;

    padding: 20px 32px 20px 32px;
    margin-bottom: 11px;
    background-color: #e1f0ff;

    > div:nth-child(1) {
      width: 10rem;
      border-right: solid #91c1f5 1px;
      margin-right: 2.5rem;
    }

    > div:nth-child(2) {
      flex: 1;
    }

    > div:nth-child(3) {
      flex: 1;
      margin-left: 0rem;
    }
  }

  .RowContainer {
    display: flex;
    flex-direction: row;
  }

  .DoctorsNavBarContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    > span {
      cursor: pointer;
    }
  }

  .DoctorTitleRowContainer {
    display: flex;
    flex-direction: row;
    margin-right: 6rem;

    > p:nth-child(1) {
      padding-right: 0.7rem;
    }
  }

  .DoctorStatsRowContainer {
    display: flex;
    flex-direction: row;

    > p {
      width: 10rem;
      margin-right: 4rem;
    }

    > div {
      width: 30rem;
      padding-right: 0.8rem;
    }

    > div:nth-child(2) {
      padding-right: 2.5rem;
    }
  }

  .resourcesBar {
    background-color: #adcded;
    height: 0.4rem;
    position: relative;
    margin-bottom: 10px;
  }

  .resourcesBarProgress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    max-width: 100%;
    background-color: #176fc8;
  }

  .progressBarLabel {
    font-size: 12px;
    margin-bottom: 5px;
    color: #1a6fc8;
  }

  .resourcesBarGreen {
    background-color: #ade1cd;
    height: 0.4rem;
    position: relative;
    margin-bottom: 10px;
  }

  .resourcesBarProgressGreen {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    max-width: 100%;
    background-color: #40b384;
  }

  .progressBarLabelGreen {
    font-size: 12px;
    margin-bottom: 5px;
    color: #40b384;
  }

  .resourcesBarRed {
    background-color: #eaaeae;
    height: 0.4rem;
    position: relative;
    margin-bottom: 10px;
  }

  .resourcesBarProgressRed {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    max-width: 100%;
    background-color: #c31b1b;
  }

  .progressBarLabelRed {
    font-size: 12px;
    margin-bottom: 5px;
    color: #c31b1b;
  }

  .BlueText {
    color: #1a6fc8;
  }

  .AddAbsenceText {
    color: #1a6fc8;
    margin-bottom: 1px solid #1a6fc8;
    padding-bottom: 3px;
    text-decoration: underline;
  }

  .BlueTextUnderlineBold {
    font-weight: bold;
    color: #1a6fc8;
    border-bottom: 2px solid #1a6fc8;
    padding-bottom: 3px;
  }

  .BlueTextBold {
    font-weight: bold;
    color: #1a6fc8;
  }

  .GreenText {
    color: #40b384;
  }

  .GreyTextSmall {
    color: #808080;
    font-size: 14px;
  }

  .SlotContainer {
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .GreenSlot {
    height: 2rem;
    max-width: 10.9rem;
    min-width: 10.9rem;
    text-align: center;
    border-radius: 5px;
    border: 1.7px #40b384 solid;
    margin: 0 10px 10px 0;
  }

  .BlueSlot {
    height: 2rem;
    max-width: 10.9rem;
    min-width: 10.9rem;
    text-align: center;
    border-radius: 5px;
    border: 1.7px #1a6fc8 solid;
    margin: 0 10px 10px 0;
  }

  .SlotTextBlue {
    color: #1a6fc8;
    font-weight: 600;
    // padding: 1rem 1rem;
    margin-bottom: 10px;
  }

  .SlotTextGreen {
    color: #40b384;
    font-weight: 600;
    // padding: 1rem 1rem;
  }

  .DoctorAbsenceContainer {
    margin-top: 0.8rem;
  }

  .AbsenceTableHeaderText {
    color: #808080;
    font-weight: 400;
  }

  .AbsenceTableColumnWidth {
    > td:nth-child(1) {
      width: 10rem;
    }
    > td:nth-child(2) {
      width: 10rem;
    }
    > td:nth-child(3) {
      width: 30rem;
    }
    > td:nth-child(4) {
      width: 15rem;
      padding-top: 0.65rem;
    }
  }

  .AbsenceTableBorderBottom {
    border-bottom: solid 2px #000;
  }

  .AddAbsenceButtonContainer {
    width: fit-content;
    padding-top: 0.8rem;
    cursor: pointer;
  }

  .EditAbsenceText {
    padding-right: 1rem;
    cursor: pointer;
    color: #1a6fc8;
    font-size: 14px;
    text-decoration: underline;
    text-transform: lowercase;
  }

  .DeleteAbsenceText {
    cursor: pointer;
    color: red;
    font-size: 14px;
    text-decoration: underline;
    text-transform: lowercase;
  }

  .listContainer {
    height: 500px;
    overflow: overlay;
  }
}
