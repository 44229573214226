@use "src/styles/variables/colors" as colors;

.LoaderOverlay {
  position: fixed;
  top: 0;
  left: 200px;
  bottom: auto;
  right: auto;
  width: calc(100vw - 200px);
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.LoaderBox {
  background: colors.$white;
  padding: 24px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    margin-top: 12px;
  }
}

.LoaderText {
  margin-top: 12px;
}