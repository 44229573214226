.FormField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 16px 0;
  
  > input {
    width: 100%;
  }
  
  > textarea, > textarea:focus {
    width: 100%;
    height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  > label {
    width: 100%;
  }
}

.Checkbox {
  input, input:focus {
    width: 24px;
    height: 24px;
  }
}

.RegularCheckbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}