@use '../../styles/variables/colors' as colors;

.Prescription {
  padding: 25px;

  &Heading {
    font-size: 1.8rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  &SubHeading {
    font-size: 1.4rem;
    font-weight: bold;
    color: colors.$blue-text;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &Select {
    width: 10rem;
    margin: 0 0 1rem 1rem;
    padding: 0.5rem 0.5rem;
    border: none;
    border-radius: 1px;
    background-color: colors.$light-gray;
    outline: none;
  }

  &Row {
    &Container {
      display: flex;
      flex-direction: row;
      padding: 20px 32px 20px 32px;
      margin-bottom: 11px;
      background-color: colors.$light-blue-background;

      > div:nth-child(1) {
        flex: 1;
      }

      > div:nth-child(2), > div:nth-child(3) {
        flex: 1;
        padding: 0 30px;
        border-left: 1px solid colors.$light-blue-border;
      }

      > div:nth-child(4) {
        flex: 0.5;
        justify-content: flex-end;
        align-self: center;
        text-align: end;
      }
    }

    &Column {
      display: flex;
      flex-direction: column;
      @extend .LabelValue;

      &ValueWrapper {
        @extend .LabelValue;
        > span:first-child {
          width: 80px;
        }

        display: flex;
      }
    }

    &Button {
      justify-self: center;
      font-weight: bold;
      width: 100%;
    }
  }

  &ClosedTasksContainer {
    .Prescription {
      &SubHeading {
        margin-top: 4rem;
        color: darken(colors.$light-gray, 20%);
      }
      &Row {
        &Container {
          background-color: darken(colors.$light-gray, 5%);
        }
      }
    }
  }
}

.Value {
  &Bold {
    font-weight: bold;
  }
  &OneLine {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}


.LabelValue {
  > span {
    margin: 0.2rem 0;
    color: colors.$blue-text;
  }
}
