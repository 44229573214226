@use '../../styles/variables/colors' as colors;

.PracticeStats {
  padding: 25px;

  .PracticeRowContainer {
    padding: 20px 32px 20px 32px;
    margin-bottom: 2.5rem;
    // background-color: #e1f0ff;

    > article:nth-child(1) {
      border-bottom: solid #3AB5AD 1px;
      margin-bottom: 1.3rem;
    }
  }

  .PracticeSecondRowContainer {
    display: flex;
    flex-direction: row;
    margin-top: 0.8rem;

    padding: 20px 32px 20px 32px;
    margin-bottom: 11px;
    background-color: #e1f0ff;

    > div:nth-child(1) {
      width: 10rem;
      border-right: solid #91c1f5 1px;
      margin-right: 2.5rem;
    }

    > div:nth-child(2) {
      flex: 1;
    }

    > div:nth-child(3) {
      flex: 1;
      margin-left: 0rem;
    }
  }

  .RowContainer {
    display: flex;
    flex-direction: row;
  }

  .PracticeTitleRowContainer {
    display: flex;
    flex-direction: row;
    margin-right: 6rem;

    > p:nth-child(1) {
      padding-right: 0.7rem;
    }
  }

  .PracticeStatsRowContainer {
    display: flex;
    flex-direction: row;

    > p {
      width: 10rem;
      margin-right: 4rem;
    }

    > div {
      width: 30rem;
      padding-right: 0.8rem;
    }

    > div:nth-child(2) {
      padding-right: 2.5rem;
    }
  }

  .BlueText {
    color: #1a6fc8;
  }

  .BlueTextBold {
    font-weight: bold;
    color: #1a6fc8;
  }

  .resourcesBar {
    background-color: #adcded;
    height: 0.4rem;
    position: relative;
    margin-bottom: 10px;
  }

  .resourcesBarProgress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    max-width: 100%;
    background-color: #176fc8;
  }

  .progressBarLabel {
    font-size: 12px;
    margin-bottom: 5px;
    color: #1a6fc8;
  }

  .resourcesBarRed {
    background-color: #eaaeae;
    height: 0.4rem;
    position: relative;
    margin-bottom: 10px;
  }

  .resourcesBarProgressRed {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    max-width: 100%;
    background-color: #c31b1b;
  }

  .progressBarLabelRed {
    font-size: 12px;
    margin-bottom: 5px;
    color: #c31b1b;
  }

  .SlotContainer {
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .BlueSlot {
    height: 5rem;
    max-width: 12rem;
    min-width: 11rem;
    text-align: start;
    border-radius: 5px;
    border: 1.7px #1a6fc8 solid;
    margin: 0 10px 10px 0;
    &:hover {
        border: 1px #ffffff solid;
        cursor: pointer;
        box-shadow: inset 1px 1px 8px rgba(227, 224, 224, 0.342),
          inset -2px -2px 10px rgba(212, 210, 210, 0.197);
      }
}

  .SlotTextBlue {
    color: #1a6fc8;
    font-weight: 600;
    padding: 1rem 1rem;
    margin-bottom: 10px;
  }
}
