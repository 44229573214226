@use '../../../styles//variables/colors' as colors;

.PrescriptionDetails {
  padding: 25px;

  &Heading {
    font-size: 1.8rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  &SubHeading {
    font-size: 1.2rem;
    font-weight: 500;
    color: colors.$blue-text;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &Row {
    &Container {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      margin-bottom: 11px;

      > div:nth-child(1) {
        width: 25%;
        padding-right: 30px;
      }

      > div:nth-child(2) {
        width: 35%;
        padding: 0 30px;
        border-left: 1px solid darken(colors.$light-gray, 10%);
      }

      > div:nth-child(3) {
        width: 40%;
        padding: 0 30px;
        border-left: 1px solid darken(colors.$light-gray, 10%);
      }
    }
  }

  &ValueBlock {
    margin-bottom: 2rem;
  }

  &CameraDropdown {
    margin-bottom: 1rem;
  }

  &CameraBox {
    border: 1px solid colors.$light-gray;
    padding: 25px;
    width: 100%; // Rezeptbreite: 420px

    video {
      width: 100%;
    }

    &Hint {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      @extend .Hint;
    }

    &ButtonContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }

  }

  &Images {
    &Hint {
      font-style: italic;
      @extend .Hint;
    }

    &Container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 1rem;

      img {


        &:not(:last-child) {
          margin-right: 0.25rem;
        }
      }
    }
  }

  &Mail {
    &HintContainer {
      margin: 4rem 0 2rem 0;
    }

    &Preview {
      background-color: colors.$light-gray;
      padding: 1rem 0.75rem;
      margin-bottom: 3rem;
      max-height: 15rem;
      overflow-y: auto;
    }
  }
}

.Value {
  &Row {
    margin: 0.4rem 0;
  }
  &Label {
    display: inline-block;
    width: 80px;
  }
  &Bold {
    font-weight: bold;
  }
  &Note {
    margin-top: 0.5rem;
    line-height: 1.75rem;
  }

  &NoApo {
    color: colors.$red;
  }
}

.Hint {
  width: 100%;
  color: darken(colors.$light-gray, 20%);
  font-size: 0.8rem;
}

.Image {
  &Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(420px / 3);

    margin-right: 0.2rem;
    margin-bottom: 0.4rem;

    img {
      width: 100%;
      height: 100%;
      box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

    span {
      margin-top: 0.2rem;
      font-size: 0.6rem;
      text-decoration: underline;
      color: colors.$red;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
